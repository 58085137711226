.main {
  /* filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5)); */
  height: 100vh;
  max-height: 450px;
  display: flex;
  justify-content: center;
  padding: 10px;
  /* background: #f8fafb; */
}

.flipCard {
  background-color: transparent;
  width: 100%;
  max-width: 450px;
  height: 100%;
  perspective: 100%;
  /* -webkit-clip-path: polygon(0% 10%, 50% 0%, 100% 10%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 20%, 50% 0%, 100% 20%, 100% 80%, 50% 100%, 0% 80%); */
}
.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.flipCard:hover .flipCardInner {
  transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  /* -webkit-clip-path: polygon(0% 10%, 50% 0%, 100% 10%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 20%, 50% 0%, 100% 20%, 100% 80%, 50% 100%, 0% 80%); */
  padding: 35% 12px 10%;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.categoryCardInActive {
  /* background-color: transparent !important; */
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 6px !important;
  align-items: center;
  box-shadow: none !important;
  background: #f8fafb !important;
}

.categoryCardInActive img {
  filter: grayscale(100%);
}

.categoryCardInActive:hover img {
  filter: grayscale(0%);
}

.flipCardFront {
  /* background: #f8fafb; */

  color: black;
}

.flipCardBack {
  /* background: #f8fafb; */

  color: white;
  transform: rotateY(180deg);
}
.h3text {
  color: black;
  font-size: 28px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
  margin-bottom: 20px;
}
.ptext {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.6);
}
