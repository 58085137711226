@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "RBRegular";
  src: url(/src/fonts/RBRegular/RB-Bold.ttf) ;
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "RBRegular";
  src: url(/src/fonts/RBRegular/RB-Light.ttf) ;
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "RBRegular";
  src: url(/src/fonts/RBRegular/RB-Regular.ttf) ;
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "RBRegular"!important;
}

.labelForm {
  font-size: 14px;
  color: #000000;

  line-height: 22px;
}